/**
 * 物流状态mall_status_code
 * 0: 进行中(兜底)
 * 1: 已下单
 * 2: 已发货
 * 3: 运输中
 * 4: 派送中
 * 5: 待取件
 * 6: 签收
 */
export const MallStatusCode = {
  PROCCESSING: 0,
  ORDER_PLACED: 1,
  SHIPPED: 2,
  IN_TRANSIT: 3,
  IN_DISPATCH: 4,
  WAITING_PICK: 5,
  DELIVERD: 6
}

export const MallStatusTextMap = {
  [MallStatusCode.PROCCESSING]: 'SHEIN_KEY_PWA_15215',
  [MallStatusCode.ORDER_PLACED]: 'SHEIN_KEY_PWA_16486',
  [MallStatusCode.SHIPPED]: 'SHEIN_KEY_PWA_27369',
  [MallStatusCode.IN_TRANSIT]: 'SHEIN_KEY_PWA_27369',
  [MallStatusCode.IN_DISPATCH]: 'SHEIN_KEY_PWA_27376',
  [MallStatusCode.WAITING_PICK]: 'SHEIN_KEY_PWA_27377',
  [MallStatusCode.DELIVERD]: 'SHEIN_KEY_PWA_24301'
}

export const CHANNE_PLIN = ['PLIN', 'PJGDJ', 'RTMVS']

export const CHANNE_PLPT = ['PLPT']

export const CHANNE_INUK = ['INUK', 'CUJPV']

export const CHANNE_ITIN = ['ITIN', 'JLYJG']

export const CHANNE_AUPP = ['AUPP', 'YIDVN', 'PKWKL']

export const CHANNE_PAUN = ['PAUN']

export const CHANNE_UBIT = ['UBIT']

export const CHANNE_UBIC = ['UBIC']

export const CHANNE_TAUP = ['TAUP', 'THAU']

export const CHANNE_AUTH = ['AUTH', 'JHMAN']

export const CHANNE_AAZD = ['AAZD', 'JGQSP']

export const CHANNE_HMJP = ['HMJP', 'STEFE', 'OAZUW', 'PUJP']

export const CHANNE_JTST = ['JTST', 'GBDZH', 'AUPGJ']

export const CHANNE_TWTH = ['TWTH', 'RBYCI']

export const CHANNE_WSZXT = ['WSZXT']
